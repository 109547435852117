import jwtDecode from "jwt-decode";
import {
  ActiveMonitoringRequestBody,
  BpMonitoringRequestSchema,
  BpReadingRaw,
  BpReadingsResponseSchema,
} from "../components/Monitoring/types";

const getToken = () => {
  const token = localStorage.getItem("jwt");
  if (!token) throw new Error("cannot find token—is user authenticated?");
  return token;
};

const isDevelopment = import.meta.env.DEV;
const baseUrl = isDevelopment
  ? "http://localhost:8000"
  : `${import.meta.env.VITE_API_URL}/v2`;

const checkToken = (token: string): void => {
  const tokenData = jwtDecode(token) as { ptid?: string };
  if (
    ("ptid" in tokenData === false || !tokenData.ptid) &&
    import.meta.env.DEV === false
  ) {
    throw new Error("ptid is undefined! does the patient have an athena ID?");
  }
};

interface ActiveMonitoringResponse extends ActiveMonitoringRequestBody {
  active: true;
  enablePatientLogging: boolean;
}

export const getIsActiveMonitoringRequest = async (): Promise<
  { active: false } | ActiveMonitoringResponse
> => {
  const token = getToken();
  try {
    checkToken(token);
  } catch {
    console.warn(
      "patient does not have an athena ID—will not check if there is an active BP monitoring request"
    );
    return { active: false };
  }
  const url = `${baseUrl}/blood-pressure-monitoring-request`;
  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(url, opts);
  if (res.status !== 200)
    throw new Error(`request failed with status ${res.status}`);
  const jsonPayload = await res.json();

  const payload = BpMonitoringRequestSchema.parse(jsonPayload);
  if (!payload.activeMonitoringRequest) return { active: false };
  // we can rely on the backend to return null if the monitoring request has lapsed
  else return {
    active: true,
    enablePatientLogging: payload.enablePatientLogging,
    ...payload.activeMonitoringRequest
  };
};

export const getReadings = async () => {
  const token = getToken();
  checkToken(token);
  const url = `${baseUrl}/blood-pressure-readings`;
  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(url, opts);
  if (res.status !== 200)
    throw new Error(`request failed with status ${res.status}`);
  const data = await res.json();
  const {
    activeMonitoringRequest: { readings },
  } = BpReadingsResponseSchema.parse(data);
  return readings.sort(
    ({ timeOfReading: t1 }, { timeOfReading: t2 }) =>
      t1.getTime() - t2.getTime()
  );
};

export const postReading = async (reading: BpReadingRaw) => {
  const token = getToken();
  checkToken(token);
  const url = `${baseUrl}/blood-pressure-reading`;
  const opts: RequestInit = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reading),
  };
  const res = await fetch(url, opts);
  if (res.status !== 200)
    throw new Error(`request failed with status ${res.status}`);
  if (res.ok) return;
  else throw new Error(`${res.status} (${res.statusText})`);
};
