import { Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import to from "await-to-js";
import { useState } from "react";
import { Helmet } from "react-helmet";
import useAsyncEffect from "use-async-effect";
import { logout } from "../../api/auth";
import { getAppointments } from "../../api/scheduling";
import { EVENTS } from "../../constants/facebookEvents";
import {
  generateFacebookPixel,
  noScriptInfoFacebookPixel,
} from "../../helpers/facebook-events-helper";
import { authenticationStore } from "../../stores/authentication-store";
import { formOptionsStore } from "../../stores/form-options-store";
import { CarouselAppointmentTypes } from "./CarouselAppointmentTypes/CarouselAppointmentTypes";
import { ProgressBarLoggedOut } from "./ProgressBarLoggedOut/ProgressBarLoggedOut";
import {
  ScheduleableAppointment,
  ScheduledAppointment,
} from "./SchedulingTypes";
import { useFeatureFlag } from "../../providers/LaunchDarklyProvider";

export const SchedulingPublic = () => {
  const { miscarriageBookNowAlert } = useFeatureFlag(
    "miscarriage-book-now-alert"
  );
  const [err, setErr]: [Error | null, Function] = useState(null);
  const [availAppts, setAvailAppts]: [ScheduledAppointment[], Function] =
    useState([]);
  // get data for tracking script
  const { LEAD } = EVENTS;
  const hashedState =
    formOptionsStore?.formSettingsInfo !== undefined
      ? formOptionsStore["formSettingsInfo"]["data"]["hashed_state"]
      : undefined;
  const { href } = window.location;
  const { userId } = authenticationStore;

  useAsyncEffect(async () => {
    const { userId } = authenticationStore;
    // we need to deauth the user if they're logged in
    if (userId !== undefined) await logout();
    authenticationStore.setIsBookNowFlow(true);

    const [getApptsErr, availApptsData] = await to(getAppointments());
    if (getApptsErr || availApptsData === undefined) {
      console.error(getApptsErr);
      return setErr(getApptsErr);
    }
    // use it to filter the appts we show
    const unauthAvailAppts = [
      ...availApptsData.data.pregnancy_care_types.filter(
        ({ shortname }: ScheduleableAppointment) => shortname === "VFIR"
      ),
      ...(availApptsData.data.preconception_care_types || []),
    ];

    setAvailAppts(unauthAvailAppts);

    return () => authenticationStore.setIsBookNowFlow(false);
  }, []);

  const apptCarousel = (
    // this needs to be wrapped in its own div so the flexbox will size properly
    <div>
      <CarouselAppointmentTypes
        appointmentTypes={availAppts}
        isBookNowPath={true}
        selectedDepartment={""}
        loadingInfoUser={false}
      />
    </div>
  );

  return (
    <div>
      <Helmet>
        <title>Oula - Scheduling</title>
        <script>
          {`
            //gtag event for book_now
            gtag('event', 'conversion', {'send_to': 'AW-542953823/85ZQCJHAvfoBEN-i84IC'});
            ${generateFacebookPixel(
              LEAD.NAME,
              LEAD.ID,
              href,
              userId,
              hashedState,
              "book_now"
            )}
          `}
        </script>
        <noscript>{noScriptInfoFacebookPixel}</noscript>
      </Helmet>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        direction={"column"}
      >
        <h1 className="scheduling-title booking-title">Book an appointment</h1>
        {miscarriageBookNowAlert && (
          <Alert severity="error" style={{ marginBottom: "1.5rem" }}>
            If you are concerned about a possible miscarriage please reach out
            to us immediately at <a href="tel:7185864944">718-586-4944</a>. If
            this is an emergency, call 911.
          </Alert>
        )}
        {/*this just needs to be set to 0 since hitting "book" on an appt will redirect to a different page*/}
        <ProgressBarLoggedOut step={0} />
        <div>
          {err !== null ? (
            <Alert severity={"error"}>Error: {err["message"]}</Alert>
          ) : null}
        </div>
        {apptCarousel}
      </Grid>
    </div>
  );
};
