import { retryRequest } from "../services/requestHelpers";

export const setFacebookConversionEvent = (data) => {
  return retryRequest(
    {
      method: "post",
      url: "/api/v1/facebook_conversions/pixel_event.json",
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
      data: { data },
    },
    true
  );
};
