import { Box, Container, Grid, TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Snackbar from "@material-ui/core/Snackbar";
import { ThemeProvider } from "@material-ui/core/styles";
import { Check } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import valid from "card-validator";
import classnames from "classnames";
import { Formik } from "formik";
import _get from "lodash/get";
import _some from "lodash/some";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import { useLocation } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import useAsyncEffect from "use-async-effect";
import * as Yup from "yup";

import { getDashboard } from "../../../api/dashboard";
import { getDepartments } from "../../../api/departments";
import { updateProfile } from "../../../api/profile";
import {
  bookAppointment,
  rescheduleAppointment,
} from "../../../api/scheduling";
import { YYYY_MM_DD_ALTERNATIVE } from "../../../constants/dateFormat";
import { EVENTS } from "../../../constants/facebookEvents";
import { PATHNAMES } from "../../../constants/pathnames";
import {
  completeFirstAppointmentMsg,
  notLaterPregnacyMsg,
  notPregnancyCareTypeMsg,
  overflowedWeekToScheduleMsg,
  transferringPrenatalCareMsg,
  veryEarlyPregnancyPersonApptMsg,
  veryEarlyPregnancyVirtualApptMsg,
} from "../../../constants/toastMessages";
import { AMEX_CODE, defaultErrorText } from "../../../constants/validation";
import {
  generateFacebookPixel,
  noScriptInfoFacebookPixel,
} from "../../../helpers/facebook-events-helper";
import {
  formatCardNameHolder,
  formatCreditCardExpirationDate,
  formatCreditCardNumber,
  formatCVV,
} from "../../../helpers/validationHelpers";
import * as commonMaterial from "../../../materialDesignShared";
import { address, maxMobileScreenWidth } from "../../../services/constants";
import { appointmentStore } from "../../../stores/appointment-store";
import { authenticationStore } from "../../../stores/authentication-store";
import { formOptionsStore } from "../../../stores/form-options-store";
import * as customMaterial from "../../LogIn/LogInMaterialDesign";
import { ProgressBarLoggedOut } from "../ProgressBarLoggedOut/ProgressBarLoggedOut";

import mixpanel from "mixpanel-browser";
import { sonoShortnames } from "../../../constants/sonoShortnames";
import "./Confirmation.scss";
import goBackHelper from "./goBackHelper";
import {
  LinkWithPathBasedReload,
  useHistoryWithPathBasedReload,
} from "../../App/LinkWithPathBasedReload";
import { useFeatureFlag } from "../../../providers/LaunchDarklyProvider";

/*eslint no-undef: "error"*/

export const Confirmation = observer(({}) => {
  const [appointment, setAppointment] = useState(null);
  const [note, setNote] = useState(null);
  const [timeslot, setTimeslot] = useState(null);
  const [virtual, setVirtual] = useState(null);
  const [reschedule, setReschedule] = useState(null);
  const [noteValue, setNoteValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [trackingScript, setTrackingScript] = useState(null);
  const [settings, setSettings] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [agreedToPatientService, setAgreedToPatientService] = useState(false);
  const [departmentInfo, setDepartmentInfo] = useState();

  // TODO : Should be a good idea create a store to get
  // the info of dashboard because it is used in different
  // components of the app.
  const [dashboardData, setDashboardData] = useState({});

  const history = useHistoryWithPathBasedReload();
  const userData = authenticationStore?.userInfo;
  const is_pregnant = _get(userData, "is_pregnant");
  const ob_episode = _get(userData, "ob_episode");
  const current_week_of_pregnancy = _get(userData, "current_week_of_pregnancy");
  const userGloballyAgreedToPatientService = _get(
    userData,
    "agreed_to_patient_service"
  );
  const currentShortName =
    !!appointment && "shortName" in appointment
      ? appointment?.shortName
      : appointment?.appointment_type_shortname;
  const selectedDepartment = _get(userData, "department");
  const selectedDepartmentId = _get(selectedDepartment, "emr_departmentid");
  const est_delivery_date = _get(ob_episode, "est_delivery_date");
  const isNotPregnant = !is_pregnant || !ob_episode || !est_delivery_date;
  const existTaggedAppointment =
    authenticationStore.getTaggedAppointment() === "true";
  const appointments = _get(dashboardData, "appointments", []);
  const hasCreditCardInfo = _get(
    dashboardData,
    "patient.has_card_on_file",
    false
  );
  const enableCreditCard = _get(dashboardData, "enable_credit_card");
  const isInPersonAppointment =
    currentShortName === "OFFI" || currentShortName === "OBC";
  const isAfterFirstAppointmentType =
    isInPersonAppointment ||
    currentShortName === "OFOL" ||
    currentShortName === "VFOL" ||
    currentShortName === "OBC";
  const apptIsVFIR = currentShortName === "VFIR";
  const apptIsVCON = currentShortName === "VCON";
  const userHasVFIRApptScheduled = _some(
    appointments,
    ({ appointment_type_shortname }) => appointment_type_shortname === "VFIR"
  );
  const futureAppointmentBeforeFirstOne = _some(
    appointments,
    ({ appointment_type_shortname, start_time }) => {
      const formattedStartTime = moment(start_time).format(
        YYYY_MM_DD_ALTERNATIVE
      );
      const isAfterCurrentAppointment = moment(formattedStartTime).isAfter(
        appointment?.begin_date_range
      );

      const isVFIR = appointment_type_shortname === "VFIR";

      if (isVFIR && isAfterCurrentAppointment) return true;
    }
  );
  const formattedCurrentDate = moment(moment().format(YYYY_MM_DD_ALTERNATIVE));
  const formattedEstDeliveryDate = moment(
    moment(ob_episode?.est_delivery_date).format(YYYY_MM_DD_ALTERNATIVE)
  );
  const daysBetweenEddAndAppointment = formattedEstDeliveryDate.diff(
    moment(timeslot?.start_time).format(YYYY_MM_DD_ALTERNATIVE),
    "days"
  );

  const daysToAppointment = Math.abs(
    formattedCurrentDate.diff(timeslot?.start_time, "days")
  );

  const weeksAtAppointment = 40 - Math.ceil(daysBetweenEddAndAppointment / 7);
  const noPreviousAppointments = !appointments.length;
  const departmentIdStorage = appointmentStore.getDepartmentId();

  const vconPatientIsPregnant = currentShortName === "VCON" && is_pregnant;

  const disableSubmitButton =
    loading ||
    !agreedToPatientService ||
    vconPatientIsPregnant ||
    !appointment ||
    !timeslot;

  const showCreditCardInfo =
    enableCreditCard && !hasCreditCardInfo && apptIsVFIR;

  const daysBeforeEDD = moment(est_delivery_date).subtract(35, "days");
  const appointmentStartTime = moment(timeslot?.start_time);
  const isAfterEDD = appointmentStartTime.isAfter(daysBeforeEDD);

  // Classname variables

  const patientAgreementContainerClassName = classnames(
    "confirmation__patientAgreementContainer",
    {
      "confirmation__patientAgreementContainer--public": existTaggedAppointment,
    }
  );

  const confirmationContainerClassName = classnames(
    "location-scheduling-container mobile-container",
    {
      "public confirmation": existTaggedAppointment,
    }
  );

  const confirmationErrorModalClassName = classnames(
    "confirmation__errorModal",
    {
      "confirmation__errorModal--public": existTaggedAppointment,
    }
  );

  const { search } = useLocation();
  useAsyncEffect(async () => {
    const { data: departmentsInfo } = await getDepartments();
    const getSelectedDepartmentInfo = (departmentId) =>
      departmentsInfo?.find(
        (department) => department.emr_departmentid === parseInt(departmentId)
      );

    const queryStringDeptId = new URLSearchParams(search).get(
      "emr_departmentid"
    );

    if (queryStringDeptId) {
      setDepartmentInfo(getSelectedDepartmentInfo(queryStringDeptId));
    } else if (authenticationStore.userId) {
      const emrDepartmentId = selectedDepartmentId || departmentIdStorage;

      setDepartmentInfo(getSelectedDepartmentInfo(emrDepartmentId));
    } else if (departmentIdStorage) {
      setDepartmentInfo(getSelectedDepartmentInfo(departmentIdStorage));
    }

    const fetchedDashboardData = await getDashboard(authenticationStore.userId);
    const dashboardData = _get(fetchedDashboardData, "data.dashboard");

    setSettings(formOptionsStore.formSettingsInfo);
    setAppointment(appointmentStore.getAppointment());
    setTimeslot(appointmentStore.getAppointment()?.timeslot);
    setVirtual(
      appointmentStore.getAppointment()?.card_is_virtual ||
        appointmentStore.getAppointment()?.virtual_link
    );
    setReschedule(appointmentStore.getAppointment()?.reschedule);
    setDashboardData(dashboardData);
  }, []);

  useEffect(() => {
    setAgreedToPatientService(userGloballyAgreedToPatientService);
  }, [userGloballyAgreedToPatientService]);

  useAsyncEffect(async () => {
    const hashedState = _get(settings, "data.hashed_state");

    if (hashedState) {
      const { href } = window.location;
      const { INITIATE_CHECKOUT } = EVENTS;
      const { getTaggedAppointment, userId } = authenticationStore;

      if (getTaggedAppointment() === "true") {
        const event = "completed profile";

        setTrackingScript(
          <Helmet>
            <title>Confirm</title>
            <script>
              {`
              //gtag event for ${event}
              gtag('event', 'conversion', {'send_to': 'AW-542953823/nVtDCIravfoBEN-i84IC'});
              
              ${generateFacebookPixel(
                INITIATE_CHECKOUT.NAME,
                INITIATE_CHECKOUT.ID,
                href,
                userId,
                hashedState,
                event
              )}
          `}
            </script>
            <noscript>{noScriptInfoFacebookPixel}</noscript>
          </Helmet>
        );
      }
    }
  }, [settings]);

  const handleClose = (e, reason) => {
    if (reason === "clickaway") return;

    setLoading();
    setErrorMessage("");
  };

  const getErrorMessage = (
    isNotAbleToScheduleByType,
    // isNotAbleToScheduleByDate,
    isNotCompletedFirstAppointment,
    isNotLaterPregnancy,
    isTransferringPrenatalCare,
    isVeryEarlyPregnancyVirtualAppt,
    isVeryEarlyPregnancyPersonAppt
  ) => {
    if (isNotAbleToScheduleByType) return notPregnancyCareTypeMsg;
    // if (isNotAbleToScheduleByDate) return overflowedWeekToScheduleMsg;
    if (isNotCompletedFirstAppointment) return completeFirstAppointmentMsg;
    if (isNotLaterPregnancy) return notLaterPregnacyMsg;
    if (isTransferringPrenatalCare) return transferringPrenatalCareMsg;
    if (isVeryEarlyPregnancyVirtualAppt)
      return veryEarlyPregnancyVirtualApptMsg;
    if (isVeryEarlyPregnancyPersonAppt) return veryEarlyPregnancyPersonApptMsg;

    return "";
  };

  const saveData = async (values = {}) => {
    const isNotAbleToScheduleByType = isNotPregnant && apptIsVFIR;
    // const isNotAbleToScheduleByDate = isAfterEDD && apptIsVFIR;
    const isNotCompletedFirstAppointment =
      isAfterFirstAppointmentType &&
      (futureAppointmentBeforeFirstOne || noPreviousAppointments);
    const isNotLaterPregnancy = weeksAtAppointment < 7 && isInPersonAppointment;
    const isTransferringPrenatalCare =
      current_week_of_pregnancy >= 17 &&
      currentShortName === "VFIR" &&
      daysToAppointment < 7;
    const isVeryEarlyPregnancyVirtualAppt =
      // removed block for sub 8 week VFIR by setting value to 0—retained logic in case we want to raise the floor
      weeksAtAppointment < 0 && currentShortName === "VFIR";
    const isVeryEarlyPregnancyPersonAppt =
      weeksAtAppointment < 8 && currentShortName === "OFFI";

    // if (isNotAbleToScheduleByDate) {
    //   const isBookNowFlow = authenticationStore.getIsBookNowFlow();
    //   mixpanel.track("3rd trimester booking", {
    //     source: isBookNowFlow ? "book_now" : "scheduling",
    //   });
    // }

    if (isVeryEarlyPregnancyPersonAppt) {
      const isBookNowFlow = authenticationStore.getIsBookNowFlow();
      mixpanel.track("early pregnancy ip booking", {
        source: isBookNowFlow ? "book_now" : "scheduling",
      });
    }

    if (isVeryEarlyPregnancyVirtualAppt) {
      const isBookNowFlow = authenticationStore.getIsBookNowFlow();
      mixpanel.track("early pregnancy virtual booking", {
        source: isBookNowFlow ? "book_now" : "scheduling",
      });
    }

    if (isTransferringPrenatalCare) {
      const isBookNowFlow = authenticationStore.getIsBookNowFlow();
      mixpanel.track("transfer booking shorter than 7 days", {
        source: isBookNowFlow ? "book_now" : "scheduling",
      });
    }

    const {
      cardNumber = null,
      cvv = null,
      expirationDate = " / ",
      nameOfCardHolder = null,
    } = values;

    const selectedErrorMessage = getErrorMessage(
      isNotAbleToScheduleByType,
      // isNotAbleToScheduleByDate,
      isNotCompletedFirstAppointment,
      isNotLaterPregnancy,
      isTransferringPrenatalCare,
      isVeryEarlyPregnancyVirtualAppt,
      isVeryEarlyPregnancyPersonAppt
    );

    setLoading(true);

    try {
      if (selectedErrorMessage) {
        setErrorMessage(selectedErrorMessage);
      } else {
        const expirationMonthAndYear = expirationDate.split("/");
        const expirationMonth = expirationMonthAndYear[0]?.trim() || "";
        const expirationYear = expirationMonthAndYear[1]?.trim() || "";

        const { data } = await bookAppointment(authenticationStore.userId, {
          appointment: {
            appointment_type_id: appointment.appointment_type_id,
            external_appointment_id: timeslot.external_appointment_id,
            appointment_card_key: appointment.appointment_card_key,
            start_time: timeslot.start_time,
            duration_minutes: timeslot.duration,
            note,
          },
          payment_method: {
            account_number: cardNumber,
            card_security_code: cvv,
            expiration_month: expirationMonth,
            expiration_year: expirationYear,
            name_on_card: nameOfCardHolder,
            billing_address: address,
            billing_zip: "11201",
          },
          emr_departmentid: parseInt(
            selectedDepartmentId || departmentIdStorage
          ),
        });

        if (!userGloballyAgreedToPatientService) {
          const { data } = await updateProfile(authenticationStore.userId, {
            agreed_to_patient_service: true,
          });

          if (_get(data, "patient")) {
            authenticationStore.setUserData(data.patient);
          }
        }

        setErrorMessage(false);

        if (_get(data, "success")) {
          if (!selectedDepartment && departmentIdStorage) {
            const { data: updatedProfileData } = await updateProfile(
              authenticationStore.userId,
              {
                patient: {
                  emr_departmentid: parseInt(departmentIdStorage),
                },
              }
            );

            if (_get(updatedProfileData, "patient")) {
              authenticationStore.setUserData(updatedProfileData.patient);
            }
          }

          appointmentStore.setAppointment({
            ...appointmentStore.getAppointment(),
            ...data,
          });

          setLoading(false);
          await appointmentStore.refreshAppointments();
          history.push(
            `${PATHNAMES.BOOKED}?appointment_type_id=${appointment.appointment_type_id}`
          );
        }
        if (_get(data, "errors")) {
          setLoading(false);
        }
      }
    } catch (error) {
      const errormsg = error.response.data.errors;
      setLoading(false);
      if (
        error.response.status === 422 &&
        error.response.data.errors[0] === "slot_taken"
      ) {
        history.push(
          `${PATHNAMES.LOCATION}?appointment_type_id=${appointment?.appointment_type_id}&error=slot_taken`
        );
      } else if (error.response.status === 403) {
        setErrorMessage(errormsg);
      } else {
        history.push(
          `${PATHNAMES.LOCATION}?appointment_type_id=${appointment?.appointment_type_id}&error=other_error`
        );
      }
    }
  };

  const saveRescheduleAppointment = async () => {
    const isNotAbleToScheduleByType = isNotPregnant && apptIsVFIR;
    // const isNotAbleToScheduleByDate = isAfterEDD && apptIsVFIR;
    const isNotCompletedFirstAppointment =
      isAfterFirstAppointmentType &&
      (futureAppointmentBeforeFirstOne || noPreviousAppointments);
    const isNotLaterPregnancy = weeksAtAppointment < 7 && isInPersonAppointment;
    const isTransferringPrenatalCare =
      current_week_of_pregnancy >= 17 &&
      appointment.appointment_type_shortname === "VFIR" &&
      daysToAppointment < 7;
    // also set the GA floor for rescheduling to 0 weeks
    const isVeryEarlyPregnancyVirtualAppt =
      weeksAtAppointment < 0 &&
      appointment.appointment_type_shortname === "VFIR";
    const isVeryEarlyPregnancyPersonAppt =
      weeksAtAppointment < 8 &&
      appointment.appointment_type_shortname === "OFFI";

    // if (isNotAbleToScheduleByDate) {
    //   const isBookNowFlow = authenticationStore.getIsBookNowFlow();
    //   mixpanel.track("3rd trimester booking", {
    //     source: isBookNowFlow ? "book_now" : "scheduling",
    //   });
    // }

    if (isVeryEarlyPregnancyPersonAppt) {
      const isBookNowFlow = authenticationStore.getIsBookNowFlow();
      mixpanel.track("early pregnancy ip booking", {
        source: isBookNowFlow ? "book_now" : "scheduling",
      });
    }

    if (isVeryEarlyPregnancyVirtualAppt) {
      const isBookNowFlow = authenticationStore.getIsBookNowFlow();
      mixpanel.track("early pregnancy virtual booking", {
        source: isBookNowFlow ? "book_now" : "scheduling",
      });
    }

    if (isTransferringPrenatalCare) {
      const isBookNowFlow = authenticationStore.getIsBookNowFlow();
      mixpanel.track("transfer booking shorter than 7 days", {
        source: isBookNowFlow ? "book_now" : "scheduling",
      });
    }

    const selectedErrorMessage = getErrorMessage(
      isNotAbleToScheduleByType,
      // isNotAbleToScheduleByDate,
      isNotCompletedFirstAppointment,
      isNotLaterPregnancy,
      isTransferringPrenatalCare,
      isVeryEarlyPregnancyVirtualAppt,
      isVeryEarlyPregnancyPersonAppt
    );

    setLoading(true);
    try {
      if (selectedErrorMessage) {
        setErrorMessage(selectedErrorMessage);
      } else {
        const { data } = await rescheduleAppointment(
          authenticationStore.userId,
          appointment.appointment_id || appointment.id,
          {
            appointment_type_id: appointment.appointment_type_id,
            external_appointment_id:
              timeslot.external_appointment_id.toString(),
            appointment_card_key: appointment.appointment_card_key,
            start_time: timeslot.start_time,
            duration_minutes: timeslot.duration,
          }
        );
        if (_get(data, "success")) {
          appointmentStore.setAppointment({
            ...appointmentStore.getAppointment(),
            ...data,
          });
          setLoading(false);
          await appointmentStore.refreshAppointments();
          history.push(
            `${PATHNAMES.BOOKED}?appointment_type_id=${appointment.appointment_type_id}`
          );
        }
        if (_get(data, "errors")) {
          setLoading(false);
        }
      }
    } catch (e) {
      if (
        _get(e, "response.status") === 422 &&
        e.response.data.errors[0] === "slot_taken"
      ) {
        history.push(
          `${PATHNAMES.LOCATION}?appointment_type_id=${appointment.appointment_type_id}&error=slot_taken`
        );
      } else {
        history.push(
          `${PATHNAMES.LOCATION}?appointment_type_id=${appointment.appointment_type_id}&error=other_error`
        );
      }
      setLoading(false);
    }
  };

  const validationSchema = showCreditCardInfo
    ? Yup.object().shape({
        nameOfCardHolder: Yup.string()
          .required(defaultErrorText)
          .test(
            "name-of-card-holder",
            "Please enter a valid name",
            (name) => valid.cardholderName(name).isValid
          ),
        cardNumber: Yup.string()
          .required(defaultErrorText)
          .test(
            "card-number",
            "Credit Card number is invalid",
            (num) => valid.number(num).isValid
          ),
        expirationDate: Yup.string()
          .required(defaultErrorText)
          .test(
            "expiration-date",
            "Please enter valid expiration date",
            (expDate) => valid.expirationDate(expDate).isValid
          ),
        cvv: Yup.string()
          .required(defaultErrorText)
          .test(
            "cvv",
            "Please enter the cvv in the reverse of your credit card",
            function (cvv) {
              const isAmexCreditCard =
                this.parent.cardNumber?.slice(0, 2) === AMEX_CODE;
              const maximumDigits = isAmexCreditCard ? 4 : 3;
              return valid.cvv(cvv, maximumDigits).isValid;
            }
          ),
      })
    : Yup.object().shape({});

  let departmentDisplay = "";
  const appointmentShortname = appointment?.appointment_type_shortname;
  const isVirtual = appointment?.virtual;
  const departmentId = departmentInfo?.emr_departmentid;
  const isSonoAppt = sonoShortnames.indexOf(appointmentShortname) > -1;
  const { upperWestSideRedirect } = useFeatureFlag("upper-west-side-redirect");
  const uwsRedirect =
    upperWestSideRedirect && !isVirtual && departmentId === 11;

  if (isSonoAppt) departmentDisplay = "Downtown Manhattan 202 Spring Street";
  else if (uwsRedirect)
    departmentDisplay = "Downtown Manhattan 202 Spring Street";
  else
    departmentDisplay = `${departmentInfo?.department_name} ${departmentInfo?.display_name}`;

  return (
    <ThemeProvider theme={commonMaterial.theme}>
      {trackingScript}
      <Container
        className={confirmationContainerClassName}
        style={{
          marginTop: `${
            existTaggedAppointment && window.innerWidth > maxMobileScreenWidth
              ? "220px"
              : existTaggedAppointment &&
                window.innerWidth <= maxMobileScreenWidth
              ? "180px"
              : `${
                  authenticationStore.getTaggedAppointment() === true && "100px"
                }`
          }`,
        }}
      >
        <Snackbar
          open={!!errorMessage}
          onClose={handleClose}
          className={confirmationErrorModalClassName}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            className="confirmation__errorAlert"
          >
            {ReactHtmlParser(errorMessage)}
          </Alert>
        </Snackbar>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          style={{
            backgroundColor: "#FFF",
            borderRadius: "3px",
            marginTop: "1rem",
          }}
        >
          <div
            className={`location-scheduling-title ${
              authenticationStore.userId ? "" : "public"
            }`}
          >
            Confirm your <br /> appointment time
          </div>
          {vconPatientIsPregnant && (
            <Box maxWidth={"90%"}>
              <Alert severity="error">
                This appointment type is for non-pregnant patients only. If you
                are not currently pregnant, please update your status in{" "}
                <LinkWithPathBasedReload to="my_profile">
                  My Profile
                </LinkWithPathBasedReload>{" "}
                and try again.
              </Alert>
            </Box>
          )}
          {existTaggedAppointment && (
            <div className="progress-bar-logged">
              <ProgressBarLoggedOut step={4} />
            </div>
          )}
          <div
            className="location-container confirmation-container padding-bottom-13"
            style={{ width: "90%", maxWidth: "22rem", padding: ".5rem" }}
          >
            <div className="tag-info">
              <div className="margin-left-15">
                {virtual ? (
                  <div className="virtual-label">Virtual</div>
                ) : (
                  <div className="office-visit-label">Office Visit</div>
                )}
              </div>
            </div>
            {appointment?.appointment_type_description && (
              <div className="card-info">
                {appointment?.appointment_type_description
                  .toLowerCase()
                  .includes("visit")
                  ? appointment?.appointment_type_description
                  : `${appointment?.appointment_type_description}
                 Visit`}
              </div>
            )}
            {!virtual && <div className="card-info">{departmentDisplay}</div>}
            <div className="time">
              {moment.utc(timeslot?.start_time).format("hh:mm A")} ET
              <br />
              {moment(timeslot?.start_time).format("ddd")},{" "}
              {moment(timeslot?.start_time).format("MMM")}{" "}
              {moment(timeslot?.start_time).format("DD")},{" "}
              {moment(timeslot?.start_time).format("YYYY")}
            </div>
          </div>

          {appointment?.add_note_enabled && !reschedule && (
            <div className="confirmation-notes">
              <div className="comment-question-section">Comments/Questions</div>
              <TextField
                placeholder="Anything we need to know in advance?"
                multiline
                rows={2}
                rowsMax={8}
                value={noteValue}
                onChange={(e) => setNoteValue(e.target.value)}
              >
                `{">"}`
              </TextField>
              {!note && (
                <div className="save-button" onClick={() => setNote(noteValue)}>
                  Save
                </div>
              )}
              {note && (
                <div className="saved">
                  <Check /> Saved
                </div>
              )}
            </div>
          )}

          <div className="payment-container">
            {showCreditCardInfo && (
              <>
                <img
                  className="icon"
                  src="/icons/Lock-Main@0,5x.svg"
                  alt="lock-icon"
                />
                <div className="payment-details">Hold this appointment</div>
                <div className="payment-description">
                  <b>
                    We require a credit card to hold this appointment for you.{" "}
                  </b>
                  By giving us this information, you are authorizing us to store
                  your card information for future use. You understand and agree
                  that{" "}
                  <b>
                    if you cancel your appointment within 24 hours, we may
                    charge the card on file.{" "}
                  </b>
                  Please contact our office if you are unable to provide a
                  credit card. See our{" "}
                  <a
                    href="https://oulahealth.com/patient-service-agreement/"
                    target="_blank"
                  >
                    cancellation policy
                  </a>
                  .
                </div>
              </>
            )}

            <Formik
              initialValues={{
                nameOfCardHolder: "",
                cardNumber: "",
                expirationDate: "",
                cvv: "",
              }}
              onSubmit={reschedule ? saveRescheduleAppointment : saveData}
              validationSchema={validationSchema}
            >
              {({ handleSubmit, handleChange, values, errors, touched }) => {
                return (
                  <form className="formStyle" onSubmit={handleSubmit}>
                    {showCreditCardInfo && (
                      <>
                        <commonMaterial.CustomTextField
                          margin="normal"
                          fullWidth
                          value={formatCardNameHolder(values.nameOfCardHolder)}
                          id="nameOfCardHolder"
                          placeholder="Name of card holder"
                          onChange={handleChange}
                          error={
                            touched.nameOfCardHolder && errors.nameOfCardHolder
                          }
                          helperText={
                            touched.nameOfCardHolder && errors.nameOfCardHolder
                          }
                        />
                        <FormControl fullWidth>
                          <commonMaterial.CustomTextField
                            id="cardNumber"
                            name="cardNumber"
                            autoComplete="cc-number"
                            value={formatCreditCardNumber(values.cardNumber)}
                            color="primary"
                            margin="normal"
                            onChange={handleChange}
                            placeholder="Card number"
                            error={touched.cardNumber && errors.cardNumber}
                            helperText={touched.cardNumber && errors.cardNumber}
                          />
                        </FormControl>
                        <div className="one-row">
                          <FormControl>
                            <commonMaterial.CustomTextField
                              id="expirationDate"
                              name="expirationDate"
                              autoComplete="cc-exp"
                              value={formatCreditCardExpirationDate(
                                values.expirationDate
                              )}
                              color="primary"
                              margin="normal"
                              onChange={handleChange}
                              placeholder="MM / YY"
                              style={{ marginRight: "10px" }}
                              error={
                                touched.expirationDate && errors.expirationDate
                              }
                              helperText={
                                touched.expirationDate && errors.expirationDate
                              }
                            />
                          </FormControl>
                          <FormControl>
                            <commonMaterial.CustomTextField
                              id="cvv"
                              name="cvv"
                              autoComplete="cc-cvv"
                              value={formatCVV(values.cvv, values.cardNumber)}
                              color="primary"
                              margin="normal"
                              onChange={handleChange}
                              placeholder="CVV"
                              style={{ marginLeft: "10px" }}
                              error={touched.cvv && errors.cvv}
                              helperText={touched.cvv && errors.cvv}
                            />
                          </FormControl>
                        </div>
                      </>
                    )}

                    {/* Do not show Agreement checkbox if user has already globally accepted the terms. */}
                    {!userGloballyAgreedToPatientService ? (
                      <Grid container justifyContent="center">
                        <div className={patientAgreementContainerClassName}>
                          <customMaterial.RememberMeCheckbox
                            checked={agreedToPatientService}
                            handleChange={(e) =>
                              setAgreedToPatientService(e.target.checked)
                            }
                            label={
                              <div className="confirmation__patientAgreementLabel">
                                I agree to the{" "}
                                <a
                                  target="_blank"
                                  href="https://oulahealth.com/patient-service-agreement/"
                                  className="confirmation__patientAgreementLink"
                                >
                                  Patient Service Agreement
                                </a>
                              </div>
                            }
                            iconClassName="confirmation__patientAgreementIcon"
                          />
                        </div>
                      </Grid>
                    ) : null}
                    <div className="submit-button">
                      <commonMaterial.CustomButton
                        variant="contained"
                        size="large"
                        color="primary"
                        type="submit"
                        disabled={disableSubmitButton}
                      >
                        BOOK NOW
                        <span
                          style={{ display: !loading && "none" }}
                          className="login-loader"
                        >
                          <ClipLoader
                            color={"#ffffff"}
                            loading={loading}
                            size={13}
                          />
                        </span>
                      </commonMaterial.CustomButton>
                      <div className="skip-booking">
                        Or, skip booking and{" "}
                        <b>
                          <span
                            className={`go-back ${
                              authenticationStore.userId ? "" : "go-back-auth"
                            } `}
                            onClick={() => {
                              appointmentStore.removeTaggedAppointment();
                              mixpanel.track("remove tagged appointment", {
                                source:
                                  "Confirmation skipped, went to dashboard",
                              });
                              history.push("/dashboard");
                            }}
                          >
                            go to dashboard.
                          </span>
                        </b>
                      </div>

                      {!authenticationStore.getTaggedAppointment() && (
                        <div
                          className={`go-back ${
                            authenticationStore.userId ? "" : "go-back-auth"
                          } `}
                          style={{ marginTop: "1rem" }}
                          onClick={() => goBackHelper(history)}
                        >
                          Go back
                        </div>
                      )}
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </Grid>
      </Container>
    </ThemeProvider>
  );
});
