export const EVENTS = {
  LEAD: {
    NAME: "Lead",
    ID: "lead",
  },
  SCHEDULE: {
    NAME: "Schedule",
    ID: "schedule",
  },
  INITIATE_CHECKOUT: {
    NAME: "InitiateCheckout",
    ID: "initiate_checkout",
  },
  SIGN_UP_FORM: {
    NAME: "SignupForm",
    ID: "signup_form",
  },
  COMPLETE_REGISTRATION: {
    NAME: "CompleteRegistration",
    ID: "complete_registration",
  },
  PAGE_VIEW: {
    NAME: "PageView",
    ID: "page_view",
  },
};
