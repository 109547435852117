import { ReactElement } from "react";
import * as z from "zod";

export const BpReadingSchema = z.object({
  timeOfReading: z.number().transform((n) => new Date(n * 1000)),
  timeOfSubmission: z.number().transform((n) => new Date(n * 1000)),
  systolic: z.number(),
  diastolic: z.number(),
  patientMessaging: z.string(),
  stageCode: z.string().optional(),
});

export type BpReading = z.infer<typeof BpReadingSchema>;

export type BpReadingRaw = z.input<typeof BpReadingSchema>;

const MonitoringRequestBody = z.object({
  startDate: z.coerce.date(),
  endDate: z.coerce.date().nullable(),
  stageCode: z.string(),
});

export const BpReadingsResponseSchema = z.object({
  activeMonitoringRequest: z.object({
    readings: z.array(BpReadingSchema),
  }),
  pastMonitoringRequests: z
    .array(
      MonitoringRequestBody.extend({
        readings: z.array(BpReadingSchema),
      })
    )
    .optional(),
});

export type ActiveMonitoringRequestBody = z.infer<typeof MonitoringRequestBody>;

export const BpMonitoringRequestSchema = z.object({
  activeMonitoringRequest: MonitoringRequestBody.nullable(),
  pastMonitoringRequests: z.array(MonitoringRequestBody).optional(),
  enablePatientLogging: z.boolean(),
});
export interface BpReadingWithAbnormal extends BpReading {
  abnormal: boolean;
  code?: "normal" | "mild" | "moderate" | "severe";
  visibleText: string;
  hoverText: string;
}

export interface Field {
  name: string;
  placeholder: string;
  // include for validation
  type: "number";
}
