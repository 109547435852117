import { v4 as uuidv4 } from "uuid";
import { setFacebookConversionEvent } from "../api/facebookConversion";
import { authenticationStore } from "../stores/authentication-store";
import { EVENTS } from "../constants/facebookEvents";

const { PAGE_VIEW } = EVENTS;

const getEventId = (id = "") => `${id.toLowerCase()}_event-${uuidv4()}`;

const getFacebookEvent = (eventId, url, name, userId) => {
  const patientId = userId || null;
  const { fbc, fbp } = getfbParameters();

  return {
    event_name: name,
    event_url: url,
    event_id: eventId,
    patient_id: patientId,
    fbc,
    fbp,
  };
};

const getUserInfo = (userId, hashedState) => {
  if (userId) {
    const pixelUserInfo = authenticationStore.getFacebookPixelUserInfo() || {};

    const { email, firstname, lastname, state } = pixelUserInfo;

    return `{
            em: '${email}',
            fn: '${firstname}',
            ln: '${lastname}',
            st: '${state}'
        }`;
  } else {
    return `{
            st: '${hashedState}'
        }`;
  }
};

const getfbParameters = () => {
  let fbp = document.cookie
    .split(";")
    .filter((c) => c.includes("_fbp="))
    .map((c) => c.split("_fbp=")[1]);
  let fbc = document.cookie
    .split(";")
    .filter((c) => c.includes("_fbc="))
    .map((c) => c.split("_fbc=")[1]);

  fbp = (fbp.length && fbp[0]) || null;
  fbc = (fbc.length && fbc[0]) || null;

  if (!fbc && window.location.search.includes("fbclid=")) {
    fbc =
      "fb.1." + +new Date() + "." + window.location.search.split("fbclid=")[1];
  }

  return {
    fbp,
    fbc,
  };
};

export const generateFacebookPixel = (
  eventName,
  eventId,
  url,
  userId,
  hashedState,
  eventDescription
) => {
  const currentEventId = getEventId(eventId);
  const currentEvent = getFacebookEvent(currentEventId, url, eventName, userId);
  const pageViewEventId = getEventId(PAGE_VIEW.ID);
  const pageViewEvent = getFacebookEvent(
    pageViewEventId,
    url,
    PAGE_VIEW.NAME,
    userId
  );
  const userInfo = getUserInfo(userId, hashedState);

  setFacebookConversionEvent(pageViewEvent);
  setFacebookConversionEvent(currentEvent);

  return `
        //fb pixel code for ${eventDescription}
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '416255453105009');
        fbq('track', '${PAGE_VIEW.NAME}', ${userInfo}, {eventID: '${pageViewEventId}'});
        fbq('track', '${eventName}', ${userInfo}, {eventID: '${currentEventId}'});
    `;
};

export const noScriptInfoFacebookPixel = `
    <img height="1" width="1" style="display:none"
    src="https://www.facebook.com/tr?id=416255453105009&ev=PageView&noscript=1"
    />
`;
