import {
  Box,
  ButtonGroup,
  CircularProgress,
  Container,
  Grid,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { useState } from "react";
import useAsyncEffect from "use-async-effect";
import * as commonMaterial from "../../materialDesignShared";
import { CustomButton } from "../../materialDesignShared";
import { authenticationStore } from "../../stores/authentication-store";
import {
  ChiyoLogo,
  CoterieLogo,
  FullyFundedLogo,
  GlowbarLogo,
  HatchLogo,
  IpsaLogo,
  LotusMethodLogo,
  MotherhoodCenterLogo,
  OriginLogo,
  SummerHealthLogo,
} from "./LogoCollection";
import PerksTab from "./PerksTab";
import WorkshopsTab from "./WorkshopsTab";
import "./perks.scss";

const Perks = () => {
  const tabs = [<WorkshopsTab />, <PerksTab />];
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [perksLoadingStatus, setPerksLoadingStatus] = useState<0 | 1 | 2>(0);
  const [showPerks, setShowPerks] = useState<boolean>(false);

  useAsyncEffect(async (isMounted) => {
    try {
      await authenticationStore.refreshUserInfo();
      const { perks_eligible: perksEligible } =
        await authenticationStore.getUserData();
      if (perksEligible === true && isMounted()) {
        setShowPerks(true);
        setPerksLoadingStatus(1);
      } else if (isMounted()) {
        setShowPerks(false);
        setPerksLoadingStatus(1);
      }
    } catch (e) {
      if (isMounted()) setPerksLoadingStatus(2);
      console.error(e);
    }
  }, []);

  const content = (
    <Grid
      container
      alignItems="center"
      direction="column"
      style={{ maxWidth: "80rem" }}
    >
      <Box style={{ maxWidth: "35rem", margin: "2rem", textAlign: "center" }}>
        Exclusively for Oula patients, we’ve partnered with an amazing slate of
        brands to bring you some
        <b> extra perks, including free workshops and discount codes</b>, to
        complement your care at Oula.
      </Box>
      <ButtonGroup style={{ marginBottom: "2rem" }}>
        <CustomButton
          className={selectedTab === 0 ? "perk-button-selected" : "perk-button"}
          onClick={() => setSelectedTab(0)}
        >
          Workshops
        </CustomButton>
        <CustomButton
          className={selectedTab === 1 ? "perk-button-selected" : "perk-button"}
          onClick={() => setSelectedTab(1)}
        >
          Discounts
        </CustomButton>
      </ButtonGroup>
      <Box width={"93%"}>{tabs[selectedTab]}</Box>
    </Grid>
  );

  const checkpoint = (
    <Container
      style={{
        background: "#FFF",
        borderRadius: "10px",
        padding: "0.5rem 3rem",
        marginTop: "2.5rem",
        width: "95%",
        maxWidth: "55rem",
      }}
      className="perks-checkpoint"
    >
      <h1 className={"title"} style={{ paddingTop: 0 }}>
        Get excited!{" "}
      </h1>
      <p>
        After your first appointment, our active Oula patients{" "}
        <b>have access to discounts and free workshops</b> from our brand
        partners!
      </p>
      <Grid container justifyContent="center">
        <LotusMethodLogo />
        <MotherhoodCenterLogo />
        <FullyFundedLogo />
        <OriginLogo />
        <GlowbarLogo />
        <SummerHealthLogo />
        <IpsaLogo />
        <HatchLogo />
        <CoterieLogo />
        <ChiyoLogo />
      </Grid>
    </Container>
  );

  return (
    <Box bgcolor={"#F8EFED"} paddingBottom={"2rem"} minHeight={"66vh"}>
      <ThemeProvider theme={commonMaterial.theme}>
        <Grid
          container
          direction="column"
          alignItems="center"
          style={{ paddingTop: "1.6rem" }}
        >
          <Box className={"title"}>Workshops & Discounts</Box>
          {perksLoadingStatus === 0 && <CircularProgress />}
          {perksLoadingStatus === 1 && (showPerks ? content : checkpoint)}
          {perksLoadingStatus === 2 && (
            <Alert severity="error">
              There was a problem fetching your perks eligibility.
            </Alert>
          )}
        </Grid>
      </ThemeProvider>
    </Box>
  );
};

export default Perks;
