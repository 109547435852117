import moment from "moment";
import _get from "lodash/get";
import _some from "lodash/some";
import { YYYY_MM_DD_ALTERNATIVE } from "../constants/dateFormat";

export const getShowOfficeHours = (dashboardData) => {
  const actualDeliveryDate = _get(
    dashboardData,
    "patient.ob_episode.actual_delivery_date"
  );
  const obEpisodeStatus = _get(dashboardData, "patient.ob_episode.status");
  const currentWeekPregnancy = _get(
    dashboardData,
    "patient.current_week_of_pregnancy"
  );
  const appointments = _get(dashboardData, "appointments", []);
  const currentDate = moment(moment().format(YYYY_MM_DD_ALTERNATIVE));
  const formattedAdd =
    moment(moment(actualDeliveryDate).format(YYYY_MM_DD_ALTERNATIVE)) || null;
  const daysAfterADD = currentDate.diff(formattedAdd, "days");
  const weeksAfterADD = daysAfterADD / 7;

  const completedAppointment = _some(appointments, (appt) =>
    moment(appt.start_time).isBefore(moment())
  );

  return !!(
    (formattedAdd && daysAfterADD && weeksAfterADD >= 0 && weeksAfterADD < 9) ||
    (completedAppointment &&
      obEpisodeStatus?.toLocaleLowerCase() === "open" &&
      currentWeekPregnancy > 16)
  );
};
